import React from "react"
import { Helmet } from "react-helmet"

import OfferBase from "../../../components/offer"

const Offer = () => {
  return (
    <OfferBase offerTitle={'Peelingi chemiczne/medyczne'}>
      <Helmet title={"Kosmetologia: Peelingi chemiczne - Klinika Medycyny Estetycznej"} />
    </OfferBase>
  )
}

export default Offer
